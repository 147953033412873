import {
  MetaSwiper,
  counts,
  SafariSwiper,
  countUpArr,
} from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header && header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });

}
let isSafari = false;
if (
  navigator.userAgent.indexOf("Safari") != -1 &&
  navigator.userAgent.indexOf("Chrome") == -1
) {
  isSafari = true;

  console.log("isSafari");
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".header").toggleClass("no-scrolled");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

/*const myAtropos = Atropos({
  el: '.my-atropos',
  // rest of parameters
});*/

const specialism = document.getElementsByClassName("specialism")[0];

if (isSafari) {
  specialism?.classList.add("isSafari");
}

if (specialism) {
  const specialismItemsArr = specialism.querySelectorAll(".specialism__item");

  function newBlur(elem) {
    elem.querySelector(".info-block").style.backdropFilter = "none";
    elem.querySelector(".info-block").style.WebkitBackdropFilter = "none";

    setTimeout(function () {
      elem.querySelector(".info-block").style.backdropFilter = "blur(4px)";
      elem.querySelector(".info-block").style.WebkitBackdropFilter =
        "blur(4px)";
    }, 100);
  }

  specialismItemsArr.forEach((item, i) => {
    item.addEventListener("click", () => {
      document
        .querySelector(".specialism__item.active")
        .classList.remove("active");
      item.classList.add("active");

      if (isSafari && window.innerWidth <= 651) {
        newBlur(item);
      }
    });
  });
}

const valuesList = document.getElementsByClassName("values-list")[0];
if (valuesList) {
  const valuesListItemsArr = valuesList.querySelectorAll(".values-list__item");

  valuesListItemsArr.forEach((item, i) => {
    item.addEventListener("click", () => {
      document
        .querySelector(".values-list__item.active")
        .classList.remove("active");
      item.classList.add("active");
    });
  });
}

const footerSlider = document.getElementsByClassName("footer-slider")[0];
if (footerSlider) {
  const nextBtn = footerSlider.querySelector(".footer-slider-next");
  const submitBtn = footerSlider.querySelector(".footer-slider-submit");
  const footerSliderArr = footerSlider.querySelectorAll('.swiper-slide');
  const footerSliderLength =
    footerSlider.querySelectorAll(".swiper-slide").length - 1;
  const fileInput = footerSlider.querySelector('#cv_field-input');

  $(".default-select").niceSelect();

  const footerSwiper = new Swiper(".footer-slider", {
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: {
      nextEl: ".footer-slider-next",
      prevEl: ".footer-slider-prev",
    },
    allowTouchMove: false,
    autoHeight: true,

    breakpoints: {
      652: {
        autoHeight: false,
      },
    },
  });

  SafariSwiper(footerSwiper);

  function watchNextBtn(item, input) {
    for (let j = 0; j < input.length; j++) {

      let isAllFieldsFilled = true;

      for (let j = 0; j < input.length; j++) {
        if (input[j].value === "") {
          isAllFieldsFilled = false;
          break;
        }
      }

      if (isAllFieldsFilled) {
        console.log('All fields are filled.');
        nextBtn.classList.remove("disable-btn");
        submitBtn.classList.remove("disable-btn");
      } else {
        console.log('Some fields are empty.');
        nextBtn.classList.add("disable-btn");
        submitBtn.classList.add("disable-btn");
      }

    }
  }

  function startWatcher() {
    footerSliderArr.forEach((item, i) => {
      const input = item.querySelectorAll('input:not(#cv_field-input):not(#cv_field)');
      watchNextBtn(item, input);

      for (let j = 0; j < input.length; j++) {
        input[j].addEventListener('input', () => {
          console.log(input);
          watchNextBtn(item, input);
        })
      }
    });
  }
  startWatcher();

  fileInput.addEventListener('input', (e) => {
    startWatcher();
  })

  footerSwiper.on("activeIndexChange", function (e) {
    /*footerSliderArr.forEach((item, i) => {
      const input = footerSliderArr[0].querySelectorAll('input:not(#cv_field-input)');
      watchNextBtn(item, input);
    })*/

    const input = footerSliderArr[0].querySelectorAll('input:not(#cv_field-input)');
    watchNextBtn(footerSliderArr[0], input);

    if (footerSliderLength === e.activeIndex) {
      const input = footerSliderArr[1].querySelectorAll('input:not(#cv_field-input)');
      watchNextBtn(footerSliderArr[0], input);
      submitBtn.classList.add("active");
      nextBtn.classList.add("hidden");
    } else {
      submitBtn.classList.remove("active");
      nextBtn.classList.remove("hidden");
    }
  });

  submitBtn.classList.add("active");
  nextBtn.classList.add("hidden");

  $(document).on("click", ".default-select .option:not(.disabled)", function (t) {

    const s = $(this),
        n = s.closest(".default-select");

    if (s.data("value") === 'working-for-you') {
      /*startWatcher();*/
      submitBtn.classList.remove("active");
      nextBtn.classList.remove("hidden");
    } else {
      submitBtn.classList.add("active");
      nextBtn.classList.add("hidden");
      startWatcher();
    }
  })
}

if (document.getElementById("typed")) {
  let typed = new Typed("#typed", {
    stringsElement: "#typed-strings",
    typeSpeed: 150,
    startDelay: 570,
    backSpeed: 50,
    loop: true,
  });
}

const footerTyping = document.querySelector(".footer-slider__text.text-typing");
if (footerTyping) {
  const footerTypingInput = document.querySelector(
    ".footer-slider__input.with-typing"
  );
  footerTypingInput.addEventListener("blur", () => {
    if (footerTypingInput.value === "") {
      footerTyping.classList.remove("hidden-typed");
      footerTypingInput.classList.remove("active");
    }
  });

  footerTypingInput.addEventListener("click", () => {
    footerTyping.classList.add("hidden-typed");
    footerTypingInput.classList.add("active");
  });
}

const competitiveSlider =
  document.getElementsByClassName("competitive-slider")[0];
if (competitiveSlider) {
  const competitiveSlidesArr =
    competitiveSlider.querySelectorAll(".swiper-slide");

  if (competitiveSlidesArr.length === 8) {
    competitiveSlider.classList.add("with-design");
  }

  const competitiveSwiper = new Swiper(".competitive-slider", {
    spaceBetween: 20,
    slidesPerView: 1.1,
    navigation: {
      nextEl: ".competitive-slider-next",
      prevEl: ".competitive-slider-prev",
    },
    speed: 400,
    slideToClickedSlide: true,
    allowTouchMove: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    breakpoints: {
      452: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
      652: {
        spaceBetween: 0,
        slidesPerView: "auto",
        navigation: false,
        allowTouchMove: false,
        autoplay: false,
        slideToClickedSlide: false,
      },
    },
  });

  SafariSwiper(competitiveSwiper);

  let clicketFlag = true;

  for (let i = competitiveSlidesArr.length - 1; i >= 0; i--) {
    /*let num = (competitiveSlidesArr.length - i);

    if (i <= 3) {
      competitiveSlidesArr[i].style.zIndex = `${num}`;
    }*/

    document.addEventListener("click", function(event) {
      if (!competitiveSlidesArr[i].contains(event.target)) {
        competitiveSlidesArr[i].classList.remove("active");
      }
    });

    competitiveSlidesArr[i].addEventListener('click', () => {

      if(document.querySelector('.competitive-card.active')) {
        document.querySelector('.competitive-card.active').classList.remove('active');
      }

      if(clicketFlag && window.innerWidth <= 651) {
        competitiveSlidesArr[i].classList.add('active');
        competitiveSwiper.autoplay.stop();
      }

      if(window.innerWidth >= 652) {
        competitiveSlidesArr[i].classList.add('active');
        competitiveSwiper.autoplay.stop();
      }
    })

    competitiveSlidesArr[i].addEventListener('mouseenter', () => {
      competitiveSlidesArr[i].style.zIndex = '20';
    })

    competitiveSlidesArr[i].addEventListener('mouseleave', () => {
      competitiveSlidesArr[i].style.zIndex = '10';
    })
  }

  competitiveSwiper.on('transitionStart', function () {
    clicketFlag = false;
  });

  competitiveSwiper.on('transitionEnd', function () {
    clicketFlag = true;
  });

  if(window.innerWidth <= 651) {

    competitiveSwiper.on('slideChangeTransitionEnd', function () {
      if(document.querySelector('.competitive-card.active')) {
        document.querySelector('.competitive-card.active').classList.remove('active');
      }
    });
  }
}

const gallerySlider = document.getElementsByClassName("gallery-slider")[0];
if (gallerySlider) {
  const gallerySlidesArr = gallerySlider.querySelectorAll(".swiper-slide");

  const gallerySwiper = new Swiper(".gallery-slider", {
    spaceBetween: 20,
    slidesPerView: 1.2,
    loop: true,

    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    grabCursor: true,

    breakpoints: {
      360: {
        slidesPerView: 1.5,
        spaceBetween: 16,
      },
      451: {
        slidesPerView: 2.1,
        spaceBetween: 15,
      },
      769: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 22,
        slidesPerView: 4,
      },
    },
  });

  SafariSwiper(gallerySwiper);
}

const testimonialSlider = document.getElementsByClassName(
  "testimonials-slider"
)[0];
const testimonialSection = document.getElementsByClassName(
  "testimonials-section"
)[0];
if (testimonialSlider) {
  const testimonialPhotoArr = testimonialSlider.querySelectorAll(
    ".testimonials-slider__item"
  );
  const testimonialContent = testimonialSection.querySelector(
    ".testimonials-content"
  );
  const testimonialContainer = testimonialSection.querySelector(
    ".testimonials-container"
  );
  const testimonialContentClose = testimonialSection.querySelector(
    ".testimonials-content__close"
  );
  const testimonialItemsArr = testimonialContent.querySelectorAll(
    ".testimonials-content__item"
  );

  const testimonialSwiper = new Swiper(".testimonials-slider", {
    spaceBetween: 20,
    slidesPerView: 3,

    navigation: {
      nextEl: ".testimonials-slider-next",
      prevEl: ".testimonials-slider-prev",
    },

    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    breakpoints: {
      360: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      452: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      652: {
        spaceBetween: 15,
        slidesPerView: 5,
      },
      1025: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
    }
  });

  SafariSwiper(testimonialSwiper);

  function clearTestimonial() {
    if (
      testimonialContent.querySelector(".testimonials-content__item.active")
    ) {
      testimonialContent
        .querySelector(".testimonials-content__item.active")
        .classList.remove("active");
    }
    if (testimonialSlider.querySelector(".testimonials-slider__item.active")) {
      testimonialSlider
        .querySelector(".testimonials-slider__item.active")
        .classList.remove("active");
    }
  }

  testimonialPhotoArr.forEach((elem, i) => {
    if (window.innerWidth >= 1025) {
      elem.addEventListener("mouseenter", (e) => {
        clearTestimonial();

        if (!testimonialContent.classList.contains("active")) {
          testimonialContent.classList.add("active");
        }

        elem.classList.add("active");
        testimonialItemsArr[i].classList.add("active");
        testimonialSwiper.autoplay.stop();
      });
    } else {
      elem.addEventListener("click", (e) => {
        clearTestimonial();

        if (!testimonialContent.classList.contains("active")) {
          testimonialContent.classList.add("active");
        }

        elem.classList.add("active");
        testimonialItemsArr[i].classList.add("active");
        testimonialSwiper.autoplay.stop();
      });
    }
  });

  if (window.innerWidth >= 1025) {
    testimonialContent.addEventListener("mouseleave", (e) => {
      clearTestimonial();
      if (testimonialContent.classList.contains("active")) {
        testimonialContent.classList.remove("active");
        testimonialSwiper.autoplay.start();
      }
    });

    testimonialSection.addEventListener("mouseleave", (e) => {
      clearTestimonial();
      if (testimonialContent.classList.contains("active")) {
        testimonialContent.classList.remove("active");
        testimonialSwiper.autoplay.start();
      }
    });
  } else {
    document.addEventListener("click", (e) => {
      let target = e.target;
      if (
        !target.closest(".testimonials-container") &&
        !target.closest(".testimonials-slider__item")
      ) {
        clearTestimonial();
        if (testimonialContent.classList.contains("active")) {
          testimonialContent.classList.remove("active");
          testimonialSwiper.autoplay.start();
        }
      }
    });
  }

  testimonialContentClose.addEventListener("click", () => {
    clearTestimonial();
    if (testimonialContent.classList.contains("active")) {
      testimonialContent.classList.remove("active");
      testimonialSwiper.autoplay.start();
    }
  });
}

const valuesSwiperV2DOM = document.querySelector(".values-slider-v2");

if (valuesSwiperV2DOM) {
  const valuesSection = document.querySelector(".values-section");

  const slides = valuesSwiperV2DOM.querySelectorAll(".swiper-slide");

  const valuesSwiperV2 = new Swiper(valuesSwiperV2DOM, {
    grabCursor: true,
    slideToClickedSlide: true,
    mousewheel: {
      forceToAxis: true,
      sensitivity: 1,
      releaseOnEdges: true,
      eventsTarget: ".values-slider-v2",
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      nextEl: ".values-slider-next",
      prevEl: ".values-slider-prev",
    },
    effect: "creative",
    creativeEffect: {
      limitProgress: 1,
      prev: {
        translate: [-14, -14, 0],
      },
      next: {
        translate: ["100% + 20px", 0, 0],
      },
    },
    on: {
      slideChangeTransitionEnd(swiper) {
        const { isEnd, realIndex, slides } = swiper;
        const isTab = window.matchMedia("(max-width: 769px)").matches;

        if (isEnd && !isTab) {
          valuesSection.style.paddingBottom = "133px";
        } else {
          valuesSection.style.paddingBottom = null;
        }

        // for hidden prev 4+ slides
        slides.forEach((slide, idx) => {
          if (idx < realIndex - 3) {
            slide.classList.add("hide");
          } else {
            slide.classList.remove("hide");
          }
        });
      },
    },
    breakpoints: {
      770: {
        direction: "vertical",
        navigation: false,

        creativeEffect: {
          limitProgress: 3,
          prev: {
            translate: [0, -14, 0],
          },
          next: {
            translate: [0, "83.4%", 0],
          },
        },
      },
    },
  });

  SafariSwiper(valuesSwiperV2);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        console.log('Секция появилась в области видимости');
        valuesSwiperV2.autoplay.start();
      } else {
        console.log('Секция исчезла из области видимости');
        valuesSwiperV2.autoplay.stop();
      }
    });
  },
      {
        threshold: 0,
        rootMargin: "0% 0% -10% 0%",
      });

  observer.observe(valuesSwiperV2DOM);
}


const servicesTabs = document.getElementsByClassName("services-tabs")[0];
const servicesContent = document.getElementsByClassName("services-content")[0];
const statsContent = document.getElementsByClassName("stats-content")[0];
const statsTitle = document.getElementsByClassName("stats-title")[0];
const statsSection = document.getElementsByClassName("stats-section")[0];

if (servicesTabs && servicesContent) {
  const servicesTabsArr = servicesTabs.querySelectorAll(".services-tabs__item");
  const servicesContentArr = servicesContent.querySelectorAll(
      ".services-content__item"
  );


  let statsContentArr = [];
  if(statsContent) {
    statsContentArr = statsContent.querySelectorAll('.stats-content__item');

    statsContentArr[0].querySelectorAll('.stats-list__item').forEach((item => {
      if(!item.classList.contains('with-animation')) {
        item.classList.add('with-animation');
      }
    }));
  }

  const servicesTabsSwiper = new Swiper(".services-tabs", {
    slidesPerView: "auto",
    slideToClickedSlide: true,
  });

  SafariSwiper(servicesTabsSwiper);

  function clearStats(content) {
    if (content.querySelector(".stats-content__item.active")) {
      const statsItemActive = content.querySelector(".stats-content__item.active");
      if(document.querySelector(".progress-elem.animation")) {
        const progressActive = document.querySelectorAll(".progress-elem.animation");

        for (let i = 0; i < progressActive.length; i++) {
          progressActive[i].classList.remove("animation");
        }
      }

      statsItemActive.classList.remove("active");

      countUpArr.forEach((countUp) => {
        countUp.reset();
      })
    }
  }

  function clearTabs(tabs, content, tabsSelector, contentSelector) {
    if (tabs.querySelector(tabsSelector + ".active")) {
      tabs
          .querySelector(tabsSelector + ".active")
          .classList.remove("active");
    }
    if (content.querySelector(contentSelector + ".active")) {
      content
          .querySelector(contentSelector + ".active")
          .classList.remove("active");
    }
  }

  function nextContent(contentArr) {
    for (let i = 0; i < contentArr.length; i++) {
      if (contentArr[i].classList.contains("active")) {
        for (let i = 0; i < contentArr.length; i++) {
          if (contentArr[i].classList.contains("next-content")) {
            contentArr[i].classList.remove("next-content");
          }
        }

        const activeElements = contentArr[i];
        if (contentArr.length - 1 === i) {
          contentArr[0].classList.add("next-content");
        } else {
          const nextElements = activeElements.nextElementSibling;
          nextElements.classList.add("next-content");
        }
      }
    }
  }

  servicesTabsArr.forEach((tab, i) => {
    tab.addEventListener("click", (e) => {
      clearTabs(servicesTabs, servicesContent, ".services-tabs__item", ".services-content__item");
      tab.classList.add("active");
      servicesContentArr[i].classList.add("active");

      if(statsContent) {
        clearStats(statsContent);
        statsContentArr[i].classList.add("active");
        statsContentArr[i].querySelectorAll('.stats-list__item').forEach((item => {
          if(!item.classList.contains('with-animation')) {
            item.classList.add('with-animation');
          }
        }));
        if (statsContentArr[i].childNodes.length <= 1) {
          statsSection.classList.add('sr-only');
        } else {
          statsSection.classList.remove('sr-only');
        }
      }
    });
  });

  servicesContentArr.forEach((content, i) => {
    const childServicesTabs = content.getElementsByClassName(
      "child-services-tabs"
    )[0];
    const childServicesContent = content.getElementsByClassName(
      "child-services-content"
    )[0];
    if (childServicesTabs) {
      const childServicesTabsArr = childServicesTabs.querySelectorAll(
        ".child-services-tabs__item"
      );
      const childServicesContentArr = childServicesContent.querySelectorAll(
        ".child-services-content__item"
      );

      childServicesTabsArr.forEach((tab, i) => {
        tab.addEventListener("click", (e) => {
          clearTabs(childServicesTabs, childServicesContent, ".child-services-tabs__item", ".child-services-content__item");
          tab.classList.add("active");
          childServicesContentArr[i].classList.add("active");
          nextContent(childServicesContentArr);
        });
      });
    }
  });
}

/*const formSelect = document.querySelector(".nice-select.form__select");
if (formSelect) {
  const placeholder = formSelect.querySelector(".current");
  const options = formSelect.querySelectorAll(".option");
  if (placeholder.textContent === "Please select") {
    placeholder.classList.add("current-placeholder");

    options.forEach((elem) => {
      elem.addEventListener("click", () => {
        placeholder.classList.remove("current-placeholder");
      });
    });
  }
}*/

if (document.getElementsByClassName("aura-card") && window.innerWidth > 551) {
  const auraCards = document.querySelectorAll(".aura-card");
  const auraCardsCursor = document.querySelectorAll(".aura-card .cursor-aura");

  if (window.innerWidth > 1025) {
    auraCards.forEach((card, i) => {
      card.addEventListener("mousemove", (e) => {
        const rect = card.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        auraCardsCursor[
          i
        ].style.transform = `translateX(${x}px) translateY(${y}px)`;
      });

      card.addEventListener("mouseenter", () => {
        auraCardsCursor[i].classList.add("active");
      });

      card.addEventListener("mouseleave", () => {
        auraCardsCursor[i].classList.remove("active");
      });
    });
  }
}

if (document.getElementsByClassName("decor-circle")[0]) {
  const decors = document.querySelectorAll(".decor-circle path");
  function getPathLength(figure) {
    figure.forEach((elem) => {
      elem.style.strokeDasharray = `${elem.getTotalLength()}`;
      elem.style.strokeDashoffset = `${elem.getTotalLength()}`;
    });
  }
  getPathLength(decors);
}

const scrollEvents = () => {
  const trueScrollTarget = document.getElementsByClassName("scroll-target")[0];

  if (trueScrollTarget) {
    const scrollTarget = document.querySelectorAll(".scroll-target");
    let threshold = 0.5;
    let decorsArr = [];
    let progressArr = [];

    if (document.getElementsByClassName("scroll-decor")[0]) {
      decorsArr = document.querySelectorAll(".scroll-decor");
    }

    if (document.getElementsByClassName("progress-animation")[0]) {
      progressArr = document.querySelectorAll(
        ".progress-animation .stats-list__item"
      );
    }

    if ("IntersectionObserver" in window) {
      console.log("IntersectionObserver is supported");
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
              if (decorsArr.length !== 0) {
                decorsArr.forEach((elem, i) => {
                  if (entry.target === elem) {
                    elem.classList.add("animation");
                  }
                });
              }

              if (
                progressArr.length !== 0 &&
                entry.target.closest('.stats-list__item.with-animation')
              ) {
                const elem = entry.target;
                countUpArr.forEach((countUp) => {
                  if (countUp.el.id === elem.querySelector(".count-up").id) {
                    countUp.start();
                    const progressElem = elem.querySelector(".progress-elem");
                    const progress = Number(progressElem.dataset.progress);
                    progressElem.classList.add("animation");
                    progressElem.style.setProperty(
                      "--widthBar",
                      `${Math.min(progress, 100)}%`
                    );
                  } else {
                    countUp.start();
                  }
                });
              }
            }
          });
        },
        {
          threshold: 0,
          rootMargin: "0% 0% -10% 0%",
        }
      );

      scrollTarget.forEach((target, index) => {
        observer.observe(target);
      });
    } else {
      console.log("IntersectionObserver doesn't supported!");
      /*$(window).on('scroll', function(){
        let  scrollToEL = $(window).scrollTop() + $(window).height() * 0.5;

        $('.scroll-target').each((ind, el)=>{
          let isIntersecting = scrollToEL > $(el).offset().top && $(el).offset().top + $(el).height() > scrollToEL
          if(isIntersecting){

            if($(el).hasClass('video-player') && !$(el).hasClass('active')){
              $(el).addClass('active');
              el.style.width = `${videoWidth}px`;
              el.style.height = `${videoHeight}px`;
              el.style.top = `${videoTop}px`;
              el.style.borderRadius = `10px`;
            }
            if($(el).hasClass('scroll-decor') && !$(el).hasClass('animation')){
              $(el).addClass("animation");
            }
          }
        })
      })
      $(window).trigger('scroll');*/
    }
  }
};

scrollEvents();

const instances = OverlayScrollbars(document.querySelectorAll(".overlay-scrollbars"), {
  className: "os-theme-thin-dark",
});

// document.querySelector('.decor-parallelogram');
// console.log(document.querySelector('.decor-parallelogram path'))

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);
